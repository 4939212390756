import * as React from 'react'
import { Button, Tag } from 'src/design-system'
import { Card } from 'components/atoms/card'

import { ArrowsClockwise } from '@phosphor-icons/react'
import { trackEvent } from '../../services/event-tracker'
import { WithAnalytics } from 'components/with-analytics'

type IntegrationCardProps = {
  name: string
  description: React.ReactNode
  connect_href: string
  disconnect_href: string
  connected: boolean
  icon: string
  source: string
}

export const IntegrationCard = (props: IntegrationCardProps) => {
  const {
    name,
    description,
    connect_href,
    disconnect_href,
    connected,
    icon,
    source,
  } = props

  return (
    <Card className="flex flex-col">
      <div className="flex flex-row">
        <div className="flex-1">
          <div className="flex justify-center w-8 h-8">
            <img src={icon} alt={`${name} Logo`} />
          </div>
        </div>
        {connected && <Tag className="max-h-6 text-xs">Active</Tag>}
      </div>

      <div className="font-bold mt-2">{name}</div>
      <div className="text-gray-600 mt-2">{description}</div>

      {!connected && (
        <WithAnalytics
          event="$user_integration_connect_clicked"
          params={{
            name,
            source,
          }}
        >
          <Button
            as="a"
            data-turbo-action="advance"
            href={connect_href}
            className="mt-4 w-full max-w-full"
            variant="outline"
            data-method="post"
          >
            Connect
          </Button>
        </WithAnalytics>
      )}
      {connected && (
        <div className="flex flex-row gap-4">
          <Button
            as="a"
            data-turbo-action="advance"
            href={disconnect_href}
            className="mt-4 border-red-400 text-red-400 max-w-full"
            variant="outline"
            data-method="delete"
          >
            Disconnect
          </Button>
          <Button
            as="a"
            data-turbo-action="advance"
            href={connect_href}
            className="mt-4 max-w-4"
            variant="outline"
            data-method="post"
          >
            <span className="sr-only">Reconnect</span>
            <ArrowsClockwise />
          </Button>
        </div>
      )}
    </Card>
  )
}
