import React, { FC } from 'react'
import cn from 'classnames'

type CardProps = { header?: JSX.Element; className?: string }

export const Card: FC<CardProps> = ({ header, className, children }) => (
  <div className="card">
    {header && <div className="p-4 border-bottom">{header}</div>}
    <div className={cn('p-4', className)}>{children}</div>
  </div>
)
